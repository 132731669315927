import { BaseFile } from "./BaseFile";

export class ExportedSupplierFile extends BaseFile
{    
    suppliers
    brands

    constructor(params) {
        super(params)

        const { brands, suppliers } = params

        this.brands = brands        
        this.suppliers = suppliers
    }

    serailize() {
        return {
            brand_ids: !!this.brands ? this.brands.map(item => item.id) : null,
            suppliers_ids: !!this.suppliers ? this.suppliers.map(item => item.id) : null,
        }
    }
}