import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter";

export class BaseFile
{
    #id
    #name
    #message
    #URL
    #status
    #creator
    #created_at
    #updated_at
    #parameters

    get id() {
        return this.#id
    }

    get creator() {
        if (!this.#creator) return '-'

        return this.#creator.name + ' ' + this.#creator.lastname
    }

    get URL() {
        return this.#URL
    }

    get status() {
        return this.#status
    }

    get name() {
        return this.#name
    }

    get createdAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#created_at))
    }

    get updatedAt() {
        if (!this.#updated_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#updated_at))
    }

    get message() {
        return this.#message
    }

    get parameters() {
        try {            
            return JSON.parse(this.#parameters)
        } catch (error) {
            return null
        }        
    }

    constructor({ creator, parameters, name, URL, message, status, created_at, updated_at, id }) {
        this.#id = id
        this.#name = name
        this.#parameters = parameters
        this.#URL = URL
        this.#message = message
        this.#status = status
        this.#created_at = created_at
        this.#updated_at = updated_at
        this.#creator = creator        
    }
}