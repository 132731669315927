<template>
    <div>
        <h2>รายงานตามซัพ</h2>

        <v-card class="mt-4 px-6 pt-6"> 
          <v-container>                        
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  outlined                                                                                          
                  v-model="selectedSnapshot"
                  :items="snapshotList"
                  label="สต๊อกตั้งต้น *"                                    
                  dense                                                      
                  hide-details="auto"
                  item-value="id"                  
                >
                  <template v-slot:selection = "{ item }">
                    <div>{{ converters.timestampToStandardAooFormat(item.asOf) }}</div>
                  </template>

                  <template v-slot:item = "{ item }">
                    <div v-if="item.status == 'completed'">
                      {{ converters.timestampToStandardAooFormat(item.asOf) }}
                    </div>
                    <div v-else>
                      {{ converters.timestampToStandardAooFormat(item.asOf) }} ({{ item.status }})
                    </div>
                  </template>                
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="targetDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="targetDate"
                      label="จนถึงวันที่ *"
                      :prepend-icon="icons.mdiCalendar"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="targetDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(targetDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>              
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected                                 
                  cache-items
                  v-model="supplier_id"             
                  label="ซัพพลายเออร์ *"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/supplierList'"
                  dense
                  :lazySearch="true"                  
                  ref="supplierAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>              
              <v-col cols="12" md="6">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips                   
                  v-model="brand_ids"             
                  label="แบรนด์"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/brandList?supplier=' + supplier_id"
                  dense
                  :lazySearch="true"                  
                  ref="brandAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                  :disabled="!supplier_id"
                ></delay-autocomplete>
              </v-col>                            
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-btn 
                @click="generateReport"
                class="ml-auto"
                color="primary"
                :disabled="isBtnDisabled"
                >ส่งออก</v-btn>
              </v-col>
            </v-row>
          </v-container>                        
        </v-card>      

        <v-card class="mt-4">
            <v-card-title>ประวัติการส่งออกไฟล์</v-card-title>

            <div>
                <v-simple-table style="width: 100%;">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สร้างเมื่อ
                            </th>
                            <th class="text-left">
                                อัพเดธล่าสุด
                            </th>
                            <th class="text-left">
                                ไฟล์
                            </th>
                            <th class="text-left">
                                สถานะ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in files"
                            :key="item.id"
                        >
                            <td>{{ item.createdAt }}</td>
                            <td>{{ item.updatedAt }}</td>
                            <td>
                                <template v-if="item.status == 'failed'">
                                    {{ item.message }}
                                </template>
                                <template v-else-if="item.status == 'success'">
                                    <a :href="item.URL">{{ item.name }}</a>
                                </template>
                                <template v-else>
                                    กำลังประมวลผล
                                </template>          
                            </td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
              </div>            
        </v-card>
    </div>
</template>
<script>
import { ExportedSupplierFile } from '@/classes/file/ExportedSupplierFile'
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
import { timestampToStandardAooFormat } from '@/helpers/dateTimeConverter'
import errorParser from '@/plugins/errorparser'
import { mdiCalendar } from '@mdi/js'
export default {
  data() {
    return {
      tableData: [],
      icons: {},
      tabList: [],
      snapshotList: [],
      selectedSnapshot: null,
      supplier_id: null,
      brand_ids: null,
      modal: false,
      targetDate: null,
      files: [],
      converters: {
        timestampToStandardAooFormat,
      },
      localDatatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
        headers: [
          {
            text: 'วัน/เวลา',
            value: 'created_at',
          },
          {
            text: 'อัพเดธล่าสุด',
            value: 'created_at',
          },
          {
            text: 'จำนวนบิล',
            value: 'bill_count',
          },
          {
            text: 'ยอดขายก่อนส่วนลด',
            value: 'before_discount',
          },
          {
            text: 'ส่วนลด',
            value: 'discount',
          },
          {
            text: 'ยอดขาย',
            value: 'sales',
          },
          {
            text: 'กำไร (%)',
            value: 'profit',
          },
        ],
      },
      icons: {
        mdiCalendar,
      },
    }
  },
  computed: {
    isBtnDisabled() {
      return !this.supplier_id || !this.selectedSnapshot || !this.targetDate
    },
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { files, snapshots } = await asyncGet('/report/snapshotList')

      this.snapshotList = snapshots.map(item => {
        return {
          ...item,
          disabled: item.status != 'completed',
        }
      })

      this.files = files.map(item => new ExportedSupplierFile(item))
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    dateObjectToYearMonth(dateObject) {
      return dateObject.getFullYear() + '/' + (dateObject.getMonth() + 1)
    },
    timestamptoYearMonth(timestamp) {
      return this.dateObjectToYearMonth(new Date(timestamp))
    },
    async generateReport() {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      const filter = {}

      try {
        const response = await asyncPost('/report/createStockCard', {
          targetDate: this.targetDate,
          selectedSnapshot: this.selectedSnapshot,
          supplier_id: this.supplier_id,
          brand_ids: this.brand_ids,
        })

        this.files = response.map(item => new ExportedSupplierFile(item))
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  components: {},
}
</script>